@tailwind base;
@tailwind components;
@tailwind utilities;

 {
  display: block;
  background: aliceblue;
}

.skeleton {
  position: relative;
  background-color: #a8a8a8;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* The moving element */
.skeleton::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.2)),
    to(transparent)
  );

  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );

  /* Adding animation */
  animation: loading 1s infinite;
}

/* Loading Animation */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --pseudo-element-display: unset !important;
}

.react-loading-skeleton::after {
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    #afacac,
    var(--base-color)
  ) !important;
}
